import React from "react"
import Layout from "../components/_App/layout"
import SEO from "../components/_App/seo"
import { Link } from "gatsby";

const NotFoundPage = () => (
    <Layout>
        <SEO title="404: Page introuvable" />

        <div className="error-area">
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">
                        <div className="error-content">
                            <div className="notfound-404">
                                <h1>Oops! ¯\_(ツ)_/¯</h1>
                            </div>
                            <h3>404 - Page introuvable</h3>
                            <p>Il n'y a rien ici :/</p>

                            <Link to="/" className="btn btn-primary">
                                Retourner à l'accueil
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
)

export default NotFoundPage
